import {
  MainContainer,
  Title,
  SubTitle,
  BenefitContainer,
  BenefitImageContainer,
  BenefitTitle,
  BenefitDescription,
  AllBenefitsContainer
} from "./Benefits.styles";
import React from 'react';

const Benefits = ({ title, subtitle, benefits1, benefits2, benefits3 }) => {
  return(
    <MainContainer>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <AllBenefitsContainer>
        {benefits1.map((benefit, i) => {
          return (
            <BenefitContainer key={i}>
              <BenefitImageContainer src={benefit.img} />
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitDescription>{benefit.description}</BenefitDescription>
            </BenefitContainer>
          );
        })}
        {benefits2.map((benefit, i) => {
          return (
            <BenefitContainer key={i}>
              <BenefitImageContainer src={benefit.img} />
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitDescription>{benefit.description}</BenefitDescription>
            </BenefitContainer>
          );
        })}
        {benefits3.map((benefit, i) => {
          return (
            <BenefitContainer key={i}>
              <BenefitImageContainer src={benefit.img} />
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitDescription>{benefit.description}</BenefitDescription>
            </BenefitContainer>
          );
        })}
      </AllBenefitsContainer>
    </MainContainer>
  );
};

export default Benefits;
