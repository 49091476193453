import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import { queries } from "../breakpoints"
import CtaSection from "../components/sections/CtaSection"
import styled from 'styled-components';
import Benefits from "../components/page-specific/Seamless-To-Grubhub/Benefits";
import GeneralHeader from "../components/global/GeneralHeader"

const AboutGrubhubCorporate = ({ location }) => {

  const benefits1 = [
    {
      title: "Make work more delicious",
      description: "From digital meetings to virtual events, a high quality meal can boost employee morale and delight clients.",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_A.png",
    },
    {
      title: "Get more. Pay less.",
      description: "Save up to 30%* annually by setting budget controls to fit your business needs.",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_B.png",
    },
  ]

  const benefits2 = [
    {
      title: "A nationwide food network",
      description: "Cater to every team member’s taste with over 300,000 restaurant partners to order from.",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635345/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_C.png",
    },
    {
      title: "Receive ongoing account support",
      description: "Our dedicated customer success team will help you every step of the way.",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_D.png",
    },
  ]

  const benefits3 = [
    {
      title: "Save time with simple reporting",
      description: "Say goodbye to expense reports! Get one monthly invoice for all meals ordered.",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_E.png",
    },
    {
      title: "Stretch your budget further with Grubhub Perks",
      description: "Our Perks provide your team with free food, exclusive deals and more!",
      img: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635345/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Icon_F.png",
    },
  ];

  const onBeforeSubmit = () => {
    if(typeof window !== 'undefined' && typeof window.uet_report_conversion === 'function') {
      window.uet_report_conversion();
    }
  }
  
  return (
    <Layout 
      location={location} 
      title={"About Grubhub Corporate | Grubhub"}
      description={"Keep your team well-fed and happy Let’s build your meal plan <!– Submit By clicking “Submit” or by using this site, you agree to Grubhub’s terms of use and privacy policy. –> Tasty benefits everyone will enjoy Grubhub Corporate is a simple, customizable and cost-effective way to feed your employees, no matter where they’re […]"}
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "",
        subGroup: "",
        pageName: "",
        brand: "grubhub"
      }}
      >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader 
        headline="Keep your team well-fed and happy"
        preheader=""
        subheadline=""
        textColor="rgb(51, 51, 51)" 
        background="rgb(248, 248, 248)"
        formID={1613}
        businessEmailsUsage={true}
        businessEmailUTMLink='https://www.grubhub.com/lets-eat?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_about_ghc_bing'
        onBeforeSubmit={onBeforeSubmit}
        backgroundColorForm='#FFF'
        formHeading="Let's build your meal plan"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635347/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3-Header-Desktop.png"
      />
      <TextContainer>
        <StandardText></StandardText>
      </TextContainer>
      <Benefits
        title="Tasty benefits everyone will enjoy" 
        subtitle="Grubhub Corporate is a simple, customizable and cost-effective way to feed your employees, no matter where they’re working. It’s free to sign up, and you only pay for the food that’s ordered."
        benefits1={benefits1} 
        benefits2={benefits2}
        benefits3={benefits3} 
      />
      <QuoteSection 
        author="Meghan M"
        position="Office Administrator at Compass"
        backgroundColor="#F7F4F9"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Compass_Logo.png"
        quote="“Using the corporate features of Grubhub has been instrumental in providing a bit of happiness to our employees during these chaotic times. It's amazing what a meal can do for morale, and the team has been grateful for such a sincere gift!”"
      />
      <CtaSection 
          headline="Let's build your meal plan"
          to="#general-header"    
          backgroundColor="#FFF"
      />
    </Layout>
  )
}

export default AboutGrubhubCorporate

const TextContainer = styled.div`
  margin: 38px 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (${queries.max_break_md}){
    margin: 40px 8% 0px 8%;
  }
`;

const StandardText = styled.p`
  text-align: center;
  font-family: "Scandia Regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
`;

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;