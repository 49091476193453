import styled, { css } from 'styled-components';

import { queries } from '../../../breakpoints'

export const Image = styled.img`
    max-width:250px;
    width:90%;
    margin: 0 auto 25px;
`;

export const TextContent = styled.div``;

export const StyledContentWrapper = styled.div`
    ${props => props.layout === "vertical" ? 
        // Vertical layout styles
        css`
            text-align: center;
        ` :

        // Horizontal layout styles
        css`
            display:flex;
            flex-flow:column;
            justify-items: center;
            grid-template-areas: "image text";
            align-items: center;
            gap: 0 25px;
            ${Image} {
                flex: 1;
                grid-area: image;
                @media(${queries.max_break_lg}){
                    max-width:250px;
                }
                @media(${queries.min_break_lg}){
                    max-width:350px;
                }
            }
            ${TextContent}{
                flex: 1;
                text-align: left;
                grid-area: text;
                @media(${queries.max_break_lg}){
                    width: 100%;
                }
            }
            @media(${queries.min_break_lg}){
                display: grid;
                grid-template-columns: 50% 50%;
            }

        `
    }

    // Align image on right side grid area
    ${props => props.imageAlignment === "right" &&
        css`
            grid-template-areas: "text image";
        `
    }
`;


export const Headline = styled.h2`
    font-weight: bold;
    font-size: clamp(var(--fontSize-4), 5vw, var(--fontSize-7));
`;

export const Subheadline = styled.p`
    margin: 40px 0;
    font-size: clamp(var(--fontSize-0), 3vw, var(--fontSize-2));
    @media(${queries.max_break_lg}){
        margin: 10px 0 40px;
    }
`;

export const Author = styled.span`
    font-weight: bold;
`;