import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 38px;
    flex-direction: column;
    @media (${queries.max_break_md}){
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0px;
    }
    @media (${queries.max_break_sm}){
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0px;
    }
`;

export const Title = styled.h2`
    font-size: 46px;
    line-height: 56px;
    font-weight: bold;
    font-family: var(--font-body);
    text-align: center;
    margin: 0px 25%;
    @media (${queries.max_break_md}){
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        font-family: "Scandia Bold", sans-serif;
        text-align: center;
        margin: 0px 8% 15px 8%;
    }
    @media (${queries.max_break_sm}){
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        font-family: "Scandia Bold", sans-serif;
        text-align: center;
        margin: 0px 8% 15px 8%;
    }
`;

export const SubTitle = styled.p`
    font-family: "Scandia Regular", sans-serif;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-secondary);
    letter-spacing: 0;
    text-align: center;
    margin: 15px 0px;
    max-width: 900px;
    width: 100%;
`;

export const AllBenefitsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0% 10%;
    @media (${queries.max_break_md}){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0px 0px 15px 0px;
    }
    @media (${queries.max_break_sm}){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0px 0px 15px 0px;
    }
`;

export const BenefitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 45%;
    padding: 30px;
    margin: 0px auto;
    @media (${queries.max_break_md}){
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 67%;
        padding: 0px;
        margin: 15px;
    }
    @media (${queries.max_break_sm}){
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 67%;
        padding: 0px;
        margin: 15px;
        // margin: 30px 16.5% 0px 16.5%;
    }
`;

export const BenefitImageContainer = styled.div`
    height: 175px;
    width: 175px;
    background-image: url(${(props)=>props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 20px;
    @media (${queries.max_break_md}){
        height: 100px;
        width: 100px;
    }
    @media (${queries.max_break_sm}){
        height: 100px;
        width: 100px;
    }
`;

export const BenefitTitle = styled.h2`
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    font-size: 22px;
    color: var(--color-secondary);
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    @media (${queries.max_break_lg}){
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
    }
`;

export const BenefitDescription = styled.p`
    font-family: "Scandia Regular", sans-serif;
    font-size: 18px;
    line-height: 26px;
    color: var(--color-secondary);
    letter-spacing: 0;
    text-align: center;
    margin: 0px;
`;
